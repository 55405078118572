import moment from 'moment'
import axios from 'axios'

import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'
import _ from 'lodash'

import BasicObject from '@/dto/Interaction.json'
const apiUrl = 'interactions'

const initState = {
  listView: {
    list: [],
    folders: {
      list: [],
      expanded: [],
    },
    fields: [],
    total: 0,
    page: 1,
    limit: 20,
    filters: {
      filter: {
        showClosed: false,
        showNoTasks: false,
      },
      searchStr: '',
      advanced: [],
      quick: [],
    },
    sort: { sortBy: null, sortDesc: false },
    viewId: null,
    views: [],
    attrs: {},
    settings: {
      title: '',
      viewSettings: null,
      userViewsettings: null,
      objectData: {},
      selectedRows: null,
      selectedFolder: null,
    },
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async getSelectFields() {
    return [{ key: 'presentation', label: 'table.number', sortable: true }]
  },

  async addComment(context: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append(
      'data',
      JSON.stringify({
        text: payload.text,
        byEmail: payload.byEmail,
        emailTitle: payload.emailTitle,
        emailUid: payload.emailUid,
        emailId: payload.emailId,
        emailAccountId: payload.emailAccountId,
        emailType: payload.emailType,
        parentId: payload.parentId,
      })
    )

    return axios
      .post(`/interactions/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async deleteComment({ commit }: any, payload: any) {
    return axios.delete(`/interactions/comment/${payload.id}`, payload).then((response) => {
      if (response.status === 200) {
        commit('delComment', { viewId: payload.viewId, index: payload.index })
      }
      return response
    })
  },

  async addFiles({ commit }: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: payload.id }))

    return axios
      .post(`/interactions/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async changeHistoryDeletionMark({ commit }: any, payload: any) {
    const path = `/${payload.type === 'event' ? 'events' : 'interactions/comment'}/change_deletion_mark`
    return axios.post(path, { id: payload.value.id }).then((response) => {
      return response
    })
  },

  async getTasks({ commit }: any, payload: any) {
    return axios
      .post(`/interactions/tasks`, { objectId: payload.objectId })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async deleteFile({ commit }: any, params: any) {
    return axios
      .delete(`/interactions/file/${params.id}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async deleteCommentFile({ commit }: any, payload: any) {
    return axios
      .delete(`/interactions/comment/file/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit('delCommentFile', payload)
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async openFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)
          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }
          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async openCommentFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/comment/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)

          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }

          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY HH:mm:ss')
}

export const getters = { ...commonGetters }
