import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

const apiURL = 'calendar_events'

const initState = {
  events: [],
  objectViews: [],
  currentEvents: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const getters = {
  events: (state: any) => state.events,
  currentEvents: (state: any) => state.currentEvents,
  presentation: (state: any) => (viewId: any) => {
    const objectView = state.objectViews.find((el: any) => el.viewId === viewId)

    let presentation = ''
    if (objectView) {
      presentation = objectView.object.name
    }

    return presentation
  },
}

export const mutations = {
  createEvent(state: any, event: any) {
    if (event.calendar) {
      event.backgroundColor = event.calendar.color
    }
    state.events.push(event)
  },

  updateEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    if (event.calendar) {
      event.backgroundColor = event.calendar.color
    }
    return state.events.splice(index, 1, {
      ...state.events[index],
      ...event,
    })
  },

  deleteEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    return state.events.splice(index, 1)
  },

  setEvents(state: any, events: any) {
    state.events = events.map((event: any) => {
      if (event.calendar) {
        event.backgroundColor = event.calendar.color
      }
      return event
    })
  },

  updateCurrentEvents(state: any, currentEvents: any) {
    state.currentEvents = currentEvents
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAllEvents({ commit }: any, payload: any) {
    const res = await axios.get(`/${apiURL}`, payload)
    if (res.status === 200) {
      if (!payload?.noCommit) {
        commit('setEvents', res.data)
      }
      return res
    } else return false
  },

  async createEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.post(`/${apiURL}`, saveData)
    if (resItem.status === 200) {
      commit('createEvent', resItem.data)
      return true
    } else return false
  },

  async updateEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.put(`/${apiURL}`, saveData)
    if (resItem.status === 200) {
      commit('updateEvent', resItem.data)
      return true
    } else return false
  },

  async deleteEvent({ commit }: any, data: any) {
    try {
      const res = await axios.delete(`/${apiURL}`, { data })
      if (res.status === 200) {
        commit('deleteEvent', data)
      } else return false
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async updateCurrentEvents({ commit, dispatch, state }: any, payload: any) {
    const calendarId = payload?.calendarId || null
    const calendarGroup = payload?.calendarGroup || null
    const filters = payload?.filters || {}
    const events = state.events

    if (calendarId === 'all') {
      const categoryCalendarEvents = await dispatch('findCategoryCalendarEvents', filters)
      commit('updateCurrentEvents', [...events, ...categoryCalendarEvents])
    } else {
      let currentEvents: Array<any> = []
      // Private and shared calendar
      if (calendarId['myCalendar']) {
        if (calendarId['myCalendar'].length > 0) {
          const result = events.filter((e: any) => calendarId['myCalendar'].includes(e.calendarId))
          currentEvents = currentEvents.concat(result)
        }
      }

      // Category calendar
      if (calendarId['categoryCalendar']) {
        if (calendarId['categoryCalendar'].length > 0) {
          for (const id of calendarId['categoryCalendar']) {
            const dispatchName = id.charAt(0).toUpperCase() + id.slice(1)
            const result = await dispatch(`find${dispatchName}`, filters[id])
            currentEvents = currentEvents.concat(result)
          }
        }
      }
      commit('updateCurrentEvents', currentEvents)
    }
  },

  async findCategoryCalendarEvents({ dispatch }: any, filters: any) {
    const [customerRequests, salesOrders, reclamations, crmEvents] = await Promise.all([
      dispatch('findCustomerRequests', filters),
      dispatch('findSalesOrders', filters),
      dispatch('findReclamations', filters),
      dispatch('findCrmEvents', filters),
    ])
    return [...customerRequests, ...salesOrders, ...reclamations, ...crmEvents]
  },

  async findCustomerRequests({ dispatch }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
      },
    }

    if (filters.constr) {
      // @ts-ignore
      filterStr.params.filter.constructorId = filters.constr.id
    }

    if (filters.executor) {
      // @ts-ignore
      filterStr.params.filter.executorId = filters.executor.id
    }

    if (filters.customer) {
      // @ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.tags) {
      // @ts-ignore
      filterStr.params.filter.tags = filters.tags
    }

    if (filters.manager) {
      // @ts-ignore
      filterStr.params.filter.managerId = filters.manager.id
    }
    return dispatch('customerRequests/findAll', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []

      return resData.map((item: any) => {
        const dt = moment(item.executionTerm)
        item.calendarId = 'customerRequests'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },

  async findSalesOrders({ dispatch, rootState }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
        lang: rootState.auth.currentLanguage.code || 'pl',
      },
    }

    if (filters.customer) {
      //@ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.status) {
      //@ts-ignore
      filterStr.params.filter.statusId = filters.status
    }

    return dispatch('orders/findAll', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []

      return resData.map((item: any) => {
        const dt = moment(item.deliveryDate)
        item.calendarId = 'salesOrders'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },

  async findCrmEvents({ dispatch, rootState }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
        lang: rootState.auth.currentLanguage.code || 'pl',
      },
    }

    if (filters.customer) {
      //@ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.status) {
      //@ts-ignore
      filterStr.params.filter.statusId = filters.status
    }

    return dispatch('events/findAll', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []
      return resData.map((item: any) => {
        const dt = moment(item.date)
        const begin = moment(item.begin)
        const ending = moment(item.ending)
        let currentPresentation = ''
        if (item.eventType) {
          currentPresentation = currentPresentation + item.eventType.name + ' / '
        }
        if (item.counterparty) {
          currentPresentation = currentPresentation + item.counterparty.name
        }

        item.calendarId = 'crmEvents'
        item.title = currentPresentation
        item.editable = false
        item.allDay = false
        item.date = dt.format('YYYY-MM-DD')
        item.start = begin.format('YYYY-MM-DD HH:mm')
        item.end = ending.format('YYYY-MM-DD HH:mm')

        return item
      })
    })
  },

  async findReclamations({ dispatch }: any, filters: any) {
    const filterStr = {
      noCommit: true,
      params: {
        filter: {},
        statusgroupfilter: {},
      },
    }

    if (filters.period?.length === 2 && filters.period?.[0] !== null) {
      //@ts-ignore
      filterStr.params.filter.period = filters.period
    }

    if (filters.customer) {
      //@ts-ignore
      filterStr.params.filter.customerId = filters.customer.id
    }

    if (filters.status && filters.status !== 'all') {
      //@ts-ignore
      filterStr.params.statusgroupfilter.statusgroupid = filters.status
    }

    if (filters.responsible) {
      //@ts-ignore
      filterStr.params.filter.responsibleId = filters.responsible.id
    }

    if (filters.executor) {
      //@ts-ignore
      filterStr.params.filter.executorId = filters.executor.id
    }

    if (filters.manager) {
      //@ts-ignore
      filterStr.params.filter.managerId = filters.manager.id
    }

    return dispatch('reclamations/findAllReclamations', filterStr, { root: true }).then((res: any) => {
      const resData = res.data || []
      return resData.map((item: any) => {
        const dt = moment(item.executionTerm)
        item.calendarId = 'reclamations'
        item.title = item.presentation
        item.editable = false
        item.allDay = true
        item.date = dt.format('YYYY-MM-DD')
        item.start = dt.format('YYYY-MM-DD 00:00:00')
        item.end = dt.format('YYYY-MM-DD 23:59:59')
        return item
      })
    })
  },
}
