import axios from 'axios'
import _ from 'lodash'

const apiURL = 'calendars'

const initState = {
  calendars: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const getters = {
  calendars: (state: any) => state.calendars,
}

export const mutations = {
  createCalendar(state: any, calendar: any) {
    state.calendars.push(calendar)
  },

  updateCalendar(state: any, calendar: any) {
    const index = state.calendars.findIndex((e: any) => e.id === calendar.id)
    return state.calendars.splice(index, 1, {
      ...state.calendars[index],
      ...calendar,
    })
  },

  deleteCalendar(state: any, calendar: any) {
    const index = state.calendars.findIndex((e: any) => e.id === calendar.id)
    return state.calendars.splice(index, 1)
  },

  setCalendars(state: any, calendars: any) {
    return (state.calendars = calendars)
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAllCalendars({ commit }: any) {
    const res = await axios.get(`/${apiURL}`)
    if (res.status === 200) {
      commit('setCalendars', res.data)
      return true
    } else return false
  },

  async createCalendar({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.post(`/${apiURL}`, saveData)
    if (resItem.status === 200) {
      commit('createCalendar', resItem.data)
      return true
    } else return false
  },

  async updateCalendar({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.put(`/${apiURL}`, saveData)
    if (resItem.status === 200) {
      const event = resItem.data
      commit('updateCalendar', event)
      return true
    } else return false
  },

  async deleteCalendar({ commit }: any, data: any) {
    try {
      const res = await axios.delete(`/${apiURL}`, { data })
      if (res.status === 200) {
        commit('deleteCalendar', data)
      } else return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
}
